.formularioImportar {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.formularioImportar form {
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
}

input[type="file"] {
    min-width: 350px;
}

input[type="file"]::file-selector-button {
    background-color: #00995d;
    margin-left: 0px;
    margin-right: 10px;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    padding: 5px;
    border: 1px solid #0a8603;
    cursor: pointer;
    color: #fff;
    transition: 1s;
}

input[type="file"]::file-selector-button:hover {
    background-color: #B1D34B;
    border: 1px solid #00995d;
    color: #00995d;
}

.formularioImportar button {
    background-color: #00995d;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    padding: 5px;
    border: 1px solid #0a8603;
    cursor: pointer;
    color: #fff;
    transition: 1s;
}

.formularioImportar button:hover:enabled {
    background-color: #B1D34B;
    border: 1px solid #00995d;
    color: #00995d;
}

.formularioImportar button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.formularioImportar .label-link-planilha-modelo {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: blue;
    text-decoration: underline;
}

.formularioImportar .label-link-planilha-modelo:hover {
    font-weight: 700;
    cursor: pointer;
}

.copy-link {
    width: 25px;
    height: 25px;
}

.icone {
    width: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}