.card-inscricao {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(245, 247, 248);
  min-height: 100vh;
}

.botao-unimed-inscricao {
  margin: 7px;
  gap: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  --bs-btn-bg: #00995d !important;
  --bs-btn-color: #fff !important;
  --bs-btn-border-color: #00995d !important;

  --bs-btn-hover-bg: #017c4b !important;
  --bs-btn-hover-border-color: #017c4b !important;

  --bs-btn-active-bg: #017c4b !important;
  --bs-btn-active-border-color: #017c4b !important;

  --bs-btn-disabled-bg: #5ba886 !important;
  --bs-btn-disabled-border-color: #91700e !important;
  cursor: pointer;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  gap: 10px;
}

.titulo img {
  height: 50px;
}

.numero-inscricao {
  font-weight: bold;
}

.logo-unimed {
  width: 200px;
  margin: 2em auto;
}

.card {
  display: flex;
  /* flex-direction: row; */
  width: calc(100% - 2em);
  max-width: 800px;
  background: #FFF;
  padding: 2em;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sobre-inscricao {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 15px;
}

.sobre-inscricao p {
  line-height: 1.4;
  margin: 0;
}

.sobre-inscricao img {
  width: 25px;
}

.data {
  display: flex;
  align-items: center;
  gap: 5px;
}

.local {
  display: flex;
  align-items: center;
  gap: 5px;
}

.organizacao {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

@media (min-width : 768px) {

  .sobre-inscricao {
    flex-direction: row;
  }

  .sobre-inscricao div {
    width: 100%;
  }

}