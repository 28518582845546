.inscricao {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(245, 247, 248);
  min-height: 100vh;
}

.botao-unimed-inscricao {
  margin: 7px;
  gap: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  --bs-btn-bg: #00995d !important;
  --bs-btn-color: #fff !important;
  --bs-btn-border-color: #00995d !important;

  --bs-btn-hover-bg: #017c4b !important;
  --bs-btn-hover-border-color: #017c4b !important;

  --bs-btn-active-bg: #017c4b !important;
  --bs-btn-active-border-color: #017c4b !important;

  --bs-btn-disabled-bg: #5ba886 !important;
  --bs-btn-disabled-border-color: #91700e !important;
  cursor: pointer;
}

.logo-unimed {
  width: 200px;
  margin: 2em auto;
}

.div-dados-inscricao {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.div-inscricao {
  width: calc(100% - 2em);
  max-width: 800px;
  background: #FFF;
  padding: 2em;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.div-inscricao .botao-custom-unimed {
  margin: 0;
}

.div-inscricao .form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form-item .info-inscricao::after {
  content: " *";
  color: rgb(230, 31, 60);
}

.form-item .dado-inscricao {
  border: 1px solid rgb(221, 224, 228);
  border-radius: 4px;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
}

.sobre-evento {
  margin: 1em 0;
}

.sobre-evento img {
  width: 25px;
}

.sobre-evento p {
  margin: 0;
}

.sobre-evento .descricao-evento {
  margin: 0;
  padding: 0;
}

.sobre-evento .data-evento {
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: center;
}

.organizacao {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.data-evento {
  display: flex;
  gap: 5px;
}

.data-evento p {
  margin: 0;
  word-break: break-all;
}

.error-inscricao {
  display: block;
  margin: 1em;
  color: #EF7925;
}

.botao-unimed-inscricao img {
  width: 20px;
  animation: spin 1s linear infinite;
}

.error-span {
  display: block;
  height: 20px;
  color: red;
  font-size: 0.8rem;
}

.local {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

@media (min-width : 768px) {

  .div-dados-inscricao {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
}