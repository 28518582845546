.select-data-filter {
    margin: 10px auto;
    width: 100%;
  }
  
  .select-data-filter ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
  .select-data-filter ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  .select-data-filter ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  /* label {
    margin-left: 5px;
    margin-right: 5px;
  } */
  .select-data-filter .dropdown-search {
    border: solid 1px black;
    display: inline-block;
    padding: 1px 2px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    font-size: 13.3333px;
  }
  .select-data-filter .default {
    border: solid 1px grey;
    color: grey;
  }
  .select-data-filter .dropdown-input {
    width: 100%;
    display: block;
  }
  
  .select-data-filter .dropdown-list ul {
    border: 1px solid grey;
    margin: 0;
    padding: 0;
    /* display: inline-block; */
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    border-top: none;
  }
  .select-data-filter .dropdown-list li {
    list-style: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .select-data-filter .dropdown-list li:hover {
    background: rgba(0, 0, 0, 0.03);
    font-weight: bold;
  }

  
  .select-data-filter .dropdown-list li.no-result {
    color: grey;
  }
  
  .select-data-filter .dropdown-list li.no-result:hover {
    font-weight: normal;
  }