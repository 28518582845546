.div-barra-horizontal {
  top: 0;
  width: 100%;
  padding-top: 5px;
  min-height: 60px;
  display: inline-block;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.botao-custom-unimed {
  margin: 7px;
  --bs-btn-bg: #00995d !important;
  --bs-btn-color: #fff !important;
  --bs-btn-border-color: #00995d !important;

  --bs-btn-hover-bg: #017c4b !important;
  --bs-btn-hover-border-color: #017c4b !important;

  --bs-btn-active-bg: #017c4b !important;
  --bs-btn-active-border-color: #017c4b !important;

  --bs-btn-disabled-bg: #5ba886 !important;
  --bs-btn-disabled-border-color: #91700e !important;
  cursor: pointer;
}

.botao-custom-unimed:disabled {
  cursor: not-allowed;
}

.alert-error {
  margin-left: 20px;
  font-size: 16px;
  line-height: 28px;
  color: #EF7925;
}

.div-box-item-menu {
  float: left;
  padding: 5px;
}

.div-logo-unimed {
  width: 100px;
}

.div-logo-unimed .img-logo-unimed {
  height: 35px;
}

.div-box-menu {
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  border-style: solid;
  border: 1px solid;
  border-color: #ddd;
  background-color: #00995d;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
}

.div-box-menu:hover {
  background-color: #017c4b;
}

.div-box-menu .img-box-menu-svg {
  width: 25px;
  height: 25px;
  /* filter: invert(43%) sepia(22%) saturate(2266%) hue-rotate(113deg) brightness(95%) contrast(101%); */
  filter: brightness(0) saturate(100%) invert(100%) sepia(13%)
}

.text-box-menu {
  font-size: 16px;
  margin-left: 4px;
  color: white;
}

.box-painel {
  border: 1px solid;
  border-color: #ddd;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  text-align: left;
  background-color: #e5f0e5;
}

.titulo-painel {
  margin-top: 5px;
  margin-bottom: 15px;
  color: #00995d !important;
  padding: 5px 5px;
  width: 90%;
}

.img-icon-svg {
  margin-left: 5px;
  height: 25px;
  /* 
   verde
   filter: invert(53%) sepia(100%) saturate(5637%) hue-rotate(143deg) brightness(96%) contrast(101%);
   branco
   filter: brightness(0) saturate(100%) invert(100%) sepia(13%)
   preto */
  filter: invert(0%) sepia(7%) saturate(27%) hue-rotate(161deg) brightness(111%) contrast(101%);
}

.img-icon-svg:hover {
  cursor: pointer;
  height: 26px;
}

@media (width <=768px) {

  .div-barra-horizontal {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }


  .div-box-menu {
    padding: 5px;
  }


  .titulo-painel {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .text-box-menu {
    display: none;
  }

  .div-logo-unimed {
    width: 120px;
  }

  .div-logo-unimed .img-logo-unimed {
    height: 45px;
  }

  .div-box-menu .img-box-menu-svg {
    width: 35px;
    height: 35px;
  }

  .div-box-menu .img-box-menu-svg{
    cursor: pointer;
  }

}